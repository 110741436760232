import Home from "./pages/Home";
import Login from "./pages/Login";
import Register from "./pages/Register";
import "./style.scss";
import { BrowserRouter, Routes, Route, Navigate, useLocation } from "react-router-dom";
import { useContext } from "react";
import { useAuth } from "./context/AuthContext";
import ChatWindow from "./components/ChatWindow"
import PlusPage from "./components/PlusPage"
import { AnimatePresence, motion } from 'framer-motion';



function App() {
  const variants = {
    hidden: { x: '100%', opacity: 0 },
    visible: { x: 0, opacity: 1 },
  };
  const transition = {
    duration: 0.4,
    ease: 'easeInOut',
  };

  const { currentUser } = useAuth();
  console.log(currentUser);
  // const location = useLocation();

  const ProtectedRoute = ({ children }) => {
    if (!currentUser) {
      return <Navigate to="/login" />;
    }

    return children
  };

  const RootRoute = () => {
    if (currentUser) {
      return <Navigate to="/home" />;
    }
    return <Navigate to="/login" />;
  };

  return (
    <BrowserRouter>
      <AnimatePresence mode="wait">
        <Routes>
          <Route path="/">
            <Route index element={<RootRoute />} />
            <Route path="login" element={<Login/>}/>
            <Route path="register" element={<Register/>}/>
            <Route path="home" element={<ProtectedRoute><Home/></ProtectedRoute>}/>

            <Route path="chat" element={
                          <motion.div
                              initial="hidden"
                              animate="visible"
                              exit="hidden"
                              variants={variants}
                              transition={transition}
                              // className="page"
                          >
                            <ChatWindow />
                          </motion.div>
                        } />
            <Route path="plus/" element={
              // <motion.div
              //     initial="hidden"
              //     animate="visible"
              //     exit="hidden"
              //     variants={variants}
              //     transition={transition}
              //     // className="page"
              // >
                <PlusPage />
              // </motion.div>
            } />
            {/*<Route path="chat" element={<ChatWindow/>}/>*/}
          </Route>
        </Routes>
      </AnimatePresence>
    </BrowserRouter>
  );

  // return (
  //     <BrowserRouter>
  //       <AnimatePresence mode="wait">
  //         <Routes>
  //           <Route path="/" element={
  //             <motion.div
  //                 initial="hidden"
  //                 animate="visible"
  //                 exit="hidden"
  //                 variants={variants}
  //                 transition={transition}
  //                 className="page"
  //             >
  //               <ProtectedRoute><Home /></ProtectedRoute>
  //             </motion.div>
  //           } />
  //           <Route path="login" element={
  //             <motion.div
  //                 initial="hidden"
  //                 animate="visible"
  //                 exit="hidden"
  //                 variants={variants}
  //                 transition={transition}
  //                 className="page"
  //             >
  //               <Login />
  //             </motion.div>
  //           } />
  //           <Route path="register" element={
  //             <motion.div
  //                 initial="hidden"
  //                 animate="visible"
  //                 exit="hidden"
  //                 variants={variants}
  //                 transition={transition}
  //                 className="page"
  //             >
  //               <Register />
  //             </motion.div>
  //           } />
  //           <Route path="home" element={
  //             <motion.div
  //                 initial="hidden"
  //                 animate="visible"
  //                 exit="hidden"
  //                 variants={variants}
  //                 transition={transition}
  //                 className="page"
  //             >
  //               <Home />
  //             </motion.div>
  //           } />
  //           <Route path="chat/:chatId" element={
  //             <motion.div
  //                 initial="hidden"
  //                 animate="visible"
  //                 exit="hidden"
  //                 variants={variants}
  //                 transition={transition}
  //                 className="page"
  //             >
  //               <ChatWindow />
  //             </motion.div>
  //           } />
  //         </Routes>
  //       </AnimatePresence>
  //     </BrowserRouter>
  // );

}

export default App;

// import React from 'react';
// import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
// // import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
// import Home from "./pages/Home";
// import Login from "./pages/Login";
// import Register from "./pages/Register";
// import { useAuth } from './context/AuthContext';
// import ProtectedRoute from './context/ProtectedRoute';
//
// const App = () => {
//     // 使用你的认证上下文钩子来获取当前用户状态
//     const { currentUser } = useAuth();
//
//     return (
//         <Router>
//             <Routes>
//                 <Route path="/home" element={<Home />} />
//                 <Route path="/login" element={<Login />} />
//                 <Route path="/register" element={<Register />} />
//                 {/* 根据用户登录状态进行重定向 */}
//                 <Route path="/" element={currentUser ? <Navigate replace to="/home" /> : <Navigate replace to="/login" />} />
//                 <Route path="*" element={<Navigate replace to="/login" />} /> {/* 捕获所有未匹配的路由并重定向到登录页 */}
//             </Routes>
//         </Router>
//     );
// };
//
// export default App;