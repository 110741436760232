// import { createContext, useEffect, useState } from "react";
// import { auth } from "../firebase";
// import { onAuthStateChanged } from "firebase/auth";
//
// export const AuthContext = createContext();
//
// export const AuthContextProvider = ({ children }) => {
//   const [currentUser, setCurrentUser] = useState({});
//
//   useEffect(() => {
//     const unsub = onAuthStateChanged(auth, (user) => {
//       setCurrentUser(user);
//       console.log(user);
//     });
//
//     return () => {
//       unsub();
//     };
//   }, []);
//
//   return (
//     <AuthContext.Provider value={{ currentUser }}>
//       {children}
//     </AuthContext.Provider>
//   );
// };


// AuthContext.js
import React, { createContext, useContext, useEffect, useState } from "react";

export const AuthContext = createContext();

export const useAuth = () => useContext(AuthContext);

export const AuthProvider = ({ children }) => {
  const [currentUser, setCurrentUser] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    // 从localStorage获取JWT令牌
    const token = localStorage.getItem('access_token');
    if (token) {
      // 如果有令牌，尝试解码令牌来获取用户信息
      // 注意：实际应用中可能需要对令牌的有效性进行检查
      try {
        // 使用你的JWT库解码令牌，例如jwt-decode
        const decodedToken = /* your JWT decode function */ (token);
        setCurrentUser(decodedToken);
      } catch (error) {
        // 如果令牌无效或过期，从localStorage中移除
        localStorage.removeItem('access_token');
        setCurrentUser(null);
      }
    } else {
      setCurrentUser(null);
    }
    setIsLoading(false);
  }, []);

  // 登录用户并保存JWT令牌
  const login = (token) => {
    localStorage.setItem('access_token', token);
    setCurrentUser(/* decodedToken */);
  };

  // 登出用户并清除JWT令牌
  const logout = () => {
    localStorage.removeItem('access_token');
    setCurrentUser(null);
  };

  return (
      <AuthContext.Provider value={{ currentUser, login, logout, isLoading }}>
        {!isLoading && children}
      </AuthContext.Provider>
  );
};