import React, { useState, useEffect, useRef } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import axiosInstance from '../context/AxiosContext';
import VoiceMessage from './VoiceMessage';
import { motion } from 'framer-motion';

const ChatWindow = () => {
    const [socketWsChat, setSocketWsChat] = useState(null);
    const [socketWsChatBackground, setSocketWsChatBackground] = useState(null);
    const navigate = useNavigate(); // 使用 navigate 钩子进行路由跳转
    const messagesEndRef = useRef(null);  // 添加对消息底部的引用

    //身份信息
    const token = localStorage.getItem('access_token');

    //聊天信息
    const chatWindowMeta = JSON.parse(localStorage.getItem('currentChat'));
    const [title, setTitle] = useState('');
    const [showTitle, setShowTitle] = useState('');
    const [chatMessages, setChatMessages] = useState([]);
    const [showChatMessages, setShowChatMessages] = useState([]);
    const [inputMessage, setInputMessage] = useState(''); // 状态，用于存储输入的消息

    //聊天背景
    const [backgroundVideo, setBackgroundVideo] = useState('');
    const videoRef = useRef(null);

    //从数据库加载聊天记录
    const fetchMessageList = async () => {
        try {
            // console.log(chatWindowMeta);
            const response = await axiosInstance.get('/chat_message_list', { params: { ava_id: chatWindowMeta.ava_id } });

            const { message, results } = response.data;
            setChatMessages(results); // 假设后端直接返回聊天列表数据
            // 加载完消息后滚动到底部
            setTimeout(() => {
                scrollToBottom();
            }, 100);
        } catch (error) {
            console.error('Failed to fetch chat list:', error);
        }
    };

    // 添加滚动到底部的函数
    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    };

    //发送消息
    const sendMessage = (message) => {
        if (socketWsChat && socketWsChat.readyState === WebSocket.OPEN) {
            console.log(message)
            const jsonMessage = JSON.stringify({ chat_meta: chatWindowMeta, text: message });
            const data = { content_type: "text", text: message, audio_url: "", role: "user" }

            // 先更新本地消息状态
            setChatMessages(prevMessages => [...prevMessages, data]);

            try {
                socketWsChat.send(jsonMessage);
                console.log(jsonMessage);
                setInputMessage(''); // 清空输入框
            } catch (error) {
                console.error('Error sending message:', error);
                // 如果发送失败，可以选择回滚本地消息状态
                setChatMessages(prevMessages => prevMessages.slice(0, -1));
            }
        } else {
            initWsChat();
            //多请求一次
            if (socketWsChat && socketWsChat.readyState === WebSocket.OPEN) {
                console.log(message)
                const jsonMessage = JSON.stringify({ chat_meta: chatWindowMeta, text: message });
                const data = { content_type: "text", text: message, audio_url: "", role: "user" }

                // 先更新本地消息状态
                setChatMessages(prevMessages => [...prevMessages, data]);

                try {
                    socketWsChat.send(jsonMessage);
                    console.log(jsonMessage);
                    setInputMessage(''); // 清空输入框
                } catch (error) {
                    console.error('Error sending message:', error);
                    // 如果发送失败，可以选择回滚本地消息状态
                    setChatMessages(prevMessages => prevMessages.slice(0, -1));
                }
            }
            else {
                console.log("WebSocket is not connected");
            }
        }
    };

    const initWsChat = () => {
        // if (socketWsChat) {
        //     // 如果已经存在连接，先关闭它
        //     socketWsChat.close();
        // }

        const socket_ws_chat = new WebSocket(`ws://47.119.191.51:9990/api/ws_chat`)

        socket_ws_chat.onopen = () => {
            console.log('ws_chat open');
            socket_ws_chat.send(JSON.stringify({ token: token }));
        };

        socket_ws_chat.onmessage = (event) => {
            const data = JSON.parse(event.data);
            console.log('Received message:', data);

            if ("status" in data) {
                socket_ws_chat.send(JSON.stringify({ "status": "received" }));
                setTitle(data.status);  //对方正在输入
            } else {
                // 使用函数式更新确保获取最新的状态
                // setChatMessages(prevMessages => {
                //     const newMessages = [...prevMessages];
                //     newMessages.push(data);
                //     return newMessages;
                // });
                setChatMessages((prevMessages) => [...prevMessages, data]);
                setTitle(chatWindowMeta.ava_show_name);  //昵称
            }
        };

        socket_ws_chat.onerror = (error) => {
            console.error('ws_chat error:', error);
        };

        socket_ws_chat.onclose = () => {
            console.log("ws_chat closed")
        }

        setSocketWsChat(socket_ws_chat);
    };  // 添加必要的依赖

    const initWsChatBackground = () => {
        const socket_ws_chat_background = new WebSocket(`ws://47.119.191.51:9990/api/ws_chat_background`)
        socket_ws_chat_background.onopen = () => {
            console.log('ws_chat_background open');
            socket_ws_chat_background.send(JSON.stringify({ token: token }));
        };
        socket_ws_chat_background.onmessage = (event) => {
            const data = JSON.parse(event.data);
            console.log(data);
            if ("background_url" in data) {
                setBackgroundVideo(data.background_url);
                socket_ws_chat_background.send("{}");
            }
            else {
                // "info" in data
                const jsonMessage = JSON.stringify({ chat_meta: chatWindowMeta });
                socket_ws_chat_background.send(jsonMessage);
            }
        };
        socket_ws_chat_background.onerror = (error) => {
            console.error('ws_chat_background error:', error);
        };
        socket_ws_chat_background.onclose = () => {
            console.log("ws_chat_background closed")
        }

        setSocketWsChatBackground(socket_ws_chat_background);
    }

    useEffect(() => {
        console.log('Component is mounted');

        if (!chatWindowMeta) {
            console.error('No chat metadata available');
            return;
        }

        setTitle(chatWindowMeta.ava_show_name);

        // 只在组件挂载时获取消息列表
        fetchMessageList();

        // 初始化WebSocket连接
        initWsChat();

        // 初始化背景WebSocket连接
        initWsChatBackground();

        // 清理函数
        return () => {
            console.log('Clean up on unmount');
            if (socketWsChat) {
                console.log('close ws chat');
                socketWsChat.close();
            }
            if (socketWsChatBackground) {
                console.log('close ws chat background');
                socketWsChatBackground.close();
            }
        };
    }, []); // 添加必要的依赖

    useEffect(() => {
        console.log("触发")
        setShowTitle(title);
        setShowChatMessages(chatMessages);
    }, [title, chatMessages]);

    useEffect(() => {
        // 当消息更新时滚动到底部
        scrollToBottom();
    }, [showChatMessages]);

    useEffect(() => {
        // 当背景视频 URL 更新时，更新视频元素的 src 属性
        if (videoRef.current && backgroundVideo) {
            videoRef.current.src = backgroundVideo;
        }
    }, [backgroundVideo]);

    return (
        <div className="home">
            <div className="container">
                <div className="chat">
                    <div className="chatInfo">
                        <img
                            src="/left_arrow.png" // 替换为你的图标路径
                            alt="Back"
                            onClick={() => navigate(-1)}
                            style={{ cursor: 'pointer', width: '20px', height: '20px' }}
                        />
                        <h4 className='chatHeaderTitle'>{showTitle}</h4>
                    </div>
                    <div className="messages" style={{ 
                        overflow: 'auto', 
                        height: 'calc(60vh - 60px)',
                        left: 0,
                        right: 0,
                        top: '40vh',
                        position: 'fixed',
                        display: 'flex',
                        flexDirection: 'column'
                    }}>
                        <video
                            ref={videoRef}
                            autoPlay
                            loop
                            muted
                            playsInline
                            className="video-background"
                            style={{
                                position: 'fixed',
                                width: '100%',
                                height: '100%',
                                objectFit: 'cover',
                                zIndex: -1
                            }}
                        />
                        <div style={{ 
                            flex: 1, 
                            overflowY: 'auto', 
                            padding: '20px'
                        }} className="custom-scrollbar">
                            {showChatMessages.map((message, index) => {
                                if (message.role === "user") {
                                    return (
                                        // <motion.div
                                        //     key={index}
                                        //     initial={{opacity: 0, y: -20}}
                                        //     animate={{opacity: 1, y: 0}}
                                        //     transition={{duration: 0.5}}
                                        <div className="user-textmessage-container">
                                            <div className='user-textmessage'>
                                                {message.text}
                                            </div>
                                        </div>
                                    )
                                } else {
                                    if (message.content_type === 'audio') {
                                        return (
                                            // <motion.div
                                            //     key={index}
                                            //     initial={{opacity: 0, y: -20}}
                                            //     animate={{opacity: 1, y: 0}}
                                            //     transition={{duration: 0.5}}
                                            <div
                                                className="messageHeader">
                                                <img
                                                    src={chatWindowMeta.ava_avatar_url} // 假设 message 对象中包含 avatarUrl 属性
                                                    alt="Ava avatar"
                                                    style={{ width: '40px', height: '40px', borderRadius: '4px' }}
                                                />
                                                <div>
                                                    <VoiceMessage message={message} />
                                                </div>
                                            </div>
                                        );
                                    } else if (message.content_type === 'text') {
                                        return (
                                            // <motion.div
                                            //     key={index}
                                            //     initial={{opacity: 0, y: -20}}
                                            //     animate={{opacity: 1, y: 0}}
                                            //     transition={{duration: 0.5}}
                                            <div
                                                className="messageHeader">
                                                <img
                                                    src={chatWindowMeta.ava_avatar_url} // 假设 message 对象中包含 avatarUrl 属性
                                                    alt="Ava avatar"
                                                    style={{ width: '40px', height: '40px', borderRadius: '4px' }}
                                                />
                                                <div className="ava-textmessage">
                                                    {message.text}
                                                </div>
                                            </div>
                                        );
                                    }
                                }
                            })}
                            <div ref={messagesEndRef} /> {/* 添加一个底部引用元素 */}
                        </div>
                    </div>
                    <div className="inputBar">
                        <input
                            style={{ backgroundColor: 'rgba(255, 255, 255, 0.65)' }}
                            type="text"
                            value={inputMessage}
                            onChange={(e) => setInputMessage(e.target.value)}
                        />
                        {/*<img*/}
                        {/*    src="/send.png"*/}
                        {/*    alt="Send"*/}
                        {/*    onClick={handleSendMessage}*/}
                        {/*    style={{cursor: 'pointer', width: '79px', height: '56px'}}*/}
                        {/*/>*/}
                        <button onClick={() => sendMessage(inputMessage)} className="sendButton">发送</button>
                    </div>

                </div>
            </div>
        </div>
    );
}

export default ChatWindow;
