// import { doc, onSnapshot } from "firebase/firestore";
// import React, { useContext, useEffect, useState } from "react";
// import { AuthContext } from "../context/AuthContext";
// import { ChatContext } from "../context/ChatContext";
// import { db } from "../firebase";
//
// const Chats = () => {
//   const [chats, setChats] = useState([]);
//
//   const { currentUser } = useContext(AuthContext);
//   const { dispatch } = useContext(ChatContext);
//
//   useEffect(() => {
//     const getChats = () => {
//       const unsub = onSnapshot(doc(db, "userChats", currentUser.uid), (doc) => {
//         setChats(doc.data());
//       });
//
//       return () => {
//         unsub();
//       };
//     };
//
//     currentUser.uid && getChats();
//   }, [currentUser.uid]);
//
//   // console.log(Object.entries(chats));
//
//   const handleSelect = (u) => {
//     dispatch({ type: "CHANGE_USER", payload: u });
//   };
//
//   return (
//     <div className="chats">
//       {Object.entries(chats)?.sort((a,b)=>b[1].date - a[1].date).map((chat) => (
//         <div className="userChat" key={chat[0]} onClick={()=>handleSelect(chat[1].userInfo)}>
//           <img src={chat[1].userInfo.photoURL} alt="" />
//           <div className="userChatInfo">
//             <span>{chat[1].userInfo.displayName}</span>
//             <p>{chat[1].lastMessage?.text}</p>
//           </div>
//         </div>
//
//      ))}
//     </div>
//   );
// };
//
// export default Chats;
import { useNavigate, Link } from "react-router-dom";
import React, { useState, useEffect } from 'react';
import axiosInstance from '../context/AxiosContext';
import { useAuth } from '../context/AuthContext';

// const Chats = () => {
//     const [chatList, setChatList] = useState([]);
//
//     useEffect(() => {
//         const fetchChatList = async () => {
//             try {
//                 const response = await axiosInstance.get('/get_chat_list');
//
//                 const {message, chat_list} = response.data;
//                 setChatList(chat_list); // 假设后端直接返回聊天列表数据
//             } catch (error) {
//                 console.error('Failed to fetch chat list:', error);
//             }
//         };
//
//         fetchChatList();
//     }, []);
//
//     return (
//         <div style={{ width: '250px', backgroundColor: '#f0f0f0', height: '100vh', overflow: 'auto' }}>
//             <div style={{ padding: '10px' }}>
//                 <ul>
//                     {chatList.map((chat) => (
//                         <li key={chat.relationship_id} style={{ listStyle: 'none', padding: '10px', borderBottom: '1px solid #ccc' }}>
//                             <div style={{ display: 'flex', alignItems: 'center' }}>
//                                 <img src={chat.ava_avatar_url} alt="avatar" style={{ width: '40px', borderRadius: '20px' }} />
//                                 <div style={{ marginLeft: '10px' }}>
//                                     <h4>{chat.ava_show_name}</h4>
//                                     <p style={{ fontSize: '12px', color: '#666' }}>{chat.last_message}</p>
//                                 </div>
//                             </div>
//                         </li>
//                     ))}
//                 </ul>
//             </div>
//         </div>
//     );
// };
//
// export default Chats;
//


const Chats = () => {
    const { currentUser  } = useAuth();
    const [chatList, setChatList] = useState([]);
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const navigate = useNavigate();

    const toggleDropdown = () => {
        setIsDropdownOpen(!isDropdownOpen);
    };

    useEffect(() => {
        const fetchChatList = async () => {
            if (!currentUser) return;
            try {
                const response = await axiosInstance.get('/get_chat_list');
                const {message, chat_list} = response.data;
                setChatList(chat_list); // 假设后端直接返回聊天列表数据
            } catch (error) {
                console.error('An error occurred:', error);
            }
        };

        fetchChatList();
    }, []);

    const truncateString = (str, num) => {
        if (str.length <= num) {
            return str;
        }
        return str.slice(0, num) + '...';
    };

    const handleChatClick = (chat) => {
        // 将选中的聊天对象信息保存到 localStorage
        localStorage.setItem('currentChat', JSON.stringify(chat));
        // 然后重定向到聊天窗口
        // window.location.href = `#/chat/${chat.relationship_id}`; // 使用锚点链接进行导航
        navigate("/chat")
    };

    return (

        <div className='chat'>
            <div className="top-bar">
                <div className="bar-content">
                    <div className="bar-title">AVA Chat</div>
                    <img
                        src="/add.png" // 替换为你的图标路径
                        alt="Plus"
                        onClick={() => navigate("/plus")}
                        style={{cursor: 'pointer', width: '21px', height: '21px', marginLeft: 'auto', marginRight: '10px'}}
                    />
                    {/*<button onClick={toggleDropdown}>*/}
                    {/*    <img className="plus-icon"/>*/}
                    {/*</button>*/}
                </div>
            </div>
            {/*{isDropdownOpen && (*/}
            {/*    <div className="dropdown-menu">*/}
            {/*        <ul>*/}
            {/*            <li>Create New Group</li>*/}
            {/*            <li>Start Broadcast</li>*/}
            {/*            <li>Add Contacts</li>*/}
            {/*            /!* 其他菜单项 *!/*/}
            {/*        </ul>*/}
            {/*    </div>*/}
            {/*)}*/}

            <div style={{
                // width: '250px',
                backgroundColor: '#f0f0f0',
                height: '100vh',
                overflow: 'auto',
                padding: '10px'
            }}>
                <div style={{padding: '10px'}}>
                    {chatList.map((chat) => (
                        <li key={chat.relationship_id} style={{
                            listStyle: 'none',
                            padding: '10px',
                            borderBottom: '1px solid #ccc',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'left'
                        }}>
                            <div
                                // to={`/chat`}
                                onClick={() => handleChatClick(chat)}
                                style={{ textDecoration: 'none', color: 'black' }}>
                                <div style={{width: '100%', display: 'flex', alignItems: 'center'}}>
                                    <img src={chat.ava_avatar_url} alt="avatar"
                                         style={{width: '40px', borderRadius: '4px'}}/>
                                    <div style={{marginLeft: '10px', flex: 1}}>
                                        <h4 style={{fontWeight: 540}}>{chat.ava_show_name}</h4>
                                        <p style={{
                                            fontSize: '12px',
                                            color: '#666',
                                        }}>{truncateString(chat.last_message, 13)}</p>
                                    </div>
                                </div>
                            </div>
                        </li>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default Chats;