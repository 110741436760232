import React, { useState, useEffect } from 'react';
import ChatRoom from './ChatRoom';

const Chat = () => {
    const [videoUrl, setVideoUrl] = useState('');
    const [messages, setMessages] = useState([]);

    // 设置获取视频URL的WebSocket连接
    useEffect(() => {
        const videoWs = new WebSocket('ws://47.119.191.51:9990/ws_chat_background');
        videoWs.onmessage = (event) => {
            setVideoUrl(event.data); // 假设服务器直接发送URL
        };
        videoWs.onclose = () => console.log('Video WebSocket closed');
        videoWs.onerror = (error) => console.error('Video WebSocket error:', error);

        return () => videoWs.close(); // 组件卸载时关闭WebSocket连接
    }, []);

    const handleNewMessage = (newMessage) => {
        const newMessages = [...messages, newMessage];
        setMessages(newMessages);
    };

    return (
        <div className="messages" style={{ position: 'relative' }}>
            {videoUrl && (
                <video
                    autoPlay
                    loop
                    muted
                    style={{ position: 'absolute', top: 0, left: 0, width: '100%', height: '100%', objectFit: 'cover' }}
                >
                    <source src={videoUrl} type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
            )}
            <div>
                {messages.map((message, index) => (
                    <p key={index}>{message}</p>
                ))}
                <ChatRoom onNewMessage={handleNewMessage} />
            </div>
        </div>
    );
};

export default Chat;