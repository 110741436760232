import React from 'react'
// import Sidebar from '../components/Sidebar'
import Chats from '../components/Chats'
import Chat from '../components/Chat'
// import Messages from '../components/Messages'


const Home = () => {
  return (
    <div className='home'>
      <div className="container">
          <div className="chat">
              <Chats />
          </div>
      </div>
    </div>
  )

    // return (
    //     <div style={{display: 'flex', height: '100vh'}}>
    //         <Chats/>
    //         {/* 默认不显示聊天窗口，仅在路由变化时显示 */}
    //         <div style={{flex: 1, padding: '10px'}}>
    //             {/* 聊天窗口内容 */}
    //         </div>
    //     </div>
    // );
}

export default Home