import React, {useEffect, useState} from 'react';
import axios from 'axios';
import {Link, useNavigate} from 'react-router-dom';
import axiosInstance from '../context/AxiosContext';


const PlusPage = () => {
    const [query, setQuery] = useState('');
    const [resultList, setResultList] = useState([]);
    const navigate = useNavigate();

    useEffect(() => {
        handleSearch();
    }, []);

    const handleSearch = async () => {
        if (!query) return;

        try {
            const response = await axiosInstance.get(`/search_ava`,
                { params: { ava_name: query }}
            );
            const data = response.data;
            console.log(data.results);
            setResultList(data.results); // 假设返回的数据直接是搜索结果

        } catch (error) {
            console.error('Search error:', error);
        }
    };

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            handleSearch();
        }
    };

    const handleAddFriend = async (ava_id) => {
        try {
            const response = await axiosInstance.get(`/add_ava`,
                { params: { ava_id: ava_id }}
            );
            const data = response.data;
            console.log(data);
            if (data.message === "success") {
                const updatedResultList = resultList.map(result =>
                    result.ava_id === ava_id ? { ...result, is_friend: true } : result
                );
                setResultList(updatedResultList);
            }

        } catch (error) {
            console.error('Add friend error:', error);
        }
    };
    // return (
    //     <div className="searchContainer">
    //         <div className="searchBar">
    //             <input
    //                 type="text"
    //                 placeholder="搜索..."
    //                 value={query}
    //                 onChange={(e) => setQuery(e.target.value)}
    //                 onKeyPress={handleKeyPress}
    //                 className="searchInput"
    //             />
    //         </div>
    //         <div className="searchResults">
    //             {results.map((result, index) => (
    //                 <div key={index}>{result.title}</div>
    //                 ))}
    //         </div>
    //     </div>
    // );


    return (
        <div className='home'>
            <div className="container">
                <div className="chat">
                    <div className="top-bar">
                        <div className="bar-content">
                            {/*<div className="bar-title">AVA Chat</div>*/}
                            {/*         <div className="searchBar">*/}
                            <img
                                src="/left_arrow.png" // 替换为你的图标路径
                                alt="Back"
                                onClick={() => navigate(-1)}
                                style={{cursor: 'pointer', width: '20px', height: '20px'}}
                            />
                            <input
                                type="text"
                                placeholder="搜索..."
                                value={query}
                                onChange={(e) => setQuery(e.target.value)}
                                onKeyPress={handleKeyPress}
                                className="searchInput"
                            />
                            {/*</div>*/}
                        </div>
                    </div>

                    <div style={{
                        backgroundColor: '#f0f0f0',
                        height: '100vh',
                        overflow: 'auto',
                        padding: '10px'
                    }}>
                        <div style={{padding: '10px'}}>
                            {resultList.map((result) => (
                                <div key={result.ava_id} style={{
                                    listStyle: 'none',
                                    padding: '10px',
                                    borderBottom: '1px solid #ccc',
                                    display: 'flex',
                                    alignItems: 'center',
                                    justifyContent: 'space-between' // 修改为space-between以分隔两端内容
                                }}>
                                    <div style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        flex: 1
                                    }}>
                                        <img
                                            src={result.avatar_url}
                                            alt="avatar"
                                            style={{width: '40px', borderRadius: '4px'}}
                                        />
                                        <div style={{marginLeft: '10px'}}>
                                            <h4 style={{fontWeight: 540}}>{result.ava_name}</h4>
                                        </div>
                                    </div>
                                    <img
                                        src={result.is_friend ? '/added.png' : '/toadd.png'}
                                        alt="Friend Status"
                                        onClick={() => result.is_friend ? null : handleAddFriend(result.ava_id)}
                                        style={{cursor: 'pointer', width: '24px', height: '24px'}}
                                    />
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PlusPage;


/*{resultList.map((result) => (
                                // <li key={result.ava_id} style={{
                                //     listStyle: 'none',
                                //     padding: '10px',
                                //     borderBottom: '1px solid #ccc',
                                //     display: 'flex',
                                //     alignItems: 'center',
                                //     justifyContent: 'left'
                                // }}>
                                //     <Link
                                //         to={`/chat/${chat.relationship_id}`}
                                //         onClick={() => handleChatClick(chat)}
                                //         style={{textDecoration: 'none', color: 'black'}}>
                                //         <div style={{width: '100%', display: 'flex', alignItems: 'center'}}>
                                //             <img src={chat.ava_avatar_url} alt="avatar"
                                //                  style={{width: '40px', borderRadius: '4px'}}/>
                                //             <div style={{marginLeft: '10px', flex: 1}}>
                                //                 <h4 style={{fontWeight: 550}}>{chat.ava_show_name}</h4>
                                //                 <p style={{
                                //                     fontSize: '12px',
                                //                     color: '#666',
                                //                 }}>{truncateString(chat.last_message, 13)}</p>
                                //             </div>
                                //         </div>
                                //     </Link>
                                // </li>
                            // ))}*/

//搜索结果
// <div style={{
//     // width: '250px',
//     backgroundColor: '#f0f0f0',
//     height: '100vh',
//     overflow: 'auto',
//     padding: '10px'
// }}>
//     <div style={{padding: '10px'}}>
//         {resultList.map((result) => (
//             <div key={result.ava_id} style={{
//                 listStyle: 'none',
//                 padding: '10px',
//                 borderBottom: '1px solid #ccc',
//                 display: 'flex',
//                 alignItems: 'center',
//                 justifyContent: 'left'
//             }}>
//                 <div
//                     style={{
//                         textDecoration: 'none',
//                         color: 'black',
//                         width: '100%',
//                         display: 'flex',
//                         alignItems: 'center',
//                         cursor: 'pointer'  // 添加鼠标悬停时的指针样式
//                     }}
//                 >
//                     <img
//                         src={result.avatar_url}
//                         alt="avatar"
//                         style={{width: '40px', borderRadius: '4px'}}
//                     />
//                     <div style={{marginLeft: '10px', flex: 1}}>
//                         <h4 style={{fontWeight: 540}}>{result.ava_name}</h4>
//                     </div>
//                 </div>
//             </div>
//         ))}
//     </div>
// </div>