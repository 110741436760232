import React, { useState } from "react";
import Add from "../img/addAvatar.png";
import axios from "axios";
import { useNavigate, Link } from "react-router-dom";
import logo from '../img/WeChat.png';

const Register = () => {
    const [err, setErr] = useState(false);
    const [registerErr, setRegisterErr] = useState(false);
    //   const [loading, setLoading] = useState(false);
      const navigate = useNavigate();
    const [resMessage, setResMessage] = useState("");

    const handleSubmit = async (e) => {
            // setLoading(true);
        e.preventDefault();
        // console.log(e.target[0].value);
        // TODO 这里映射乱七八糟的
        // const user_name = e.target[0].value;
        const phone = e.target[0].value;
        const password = e.target[1].value;
        const confirmPassword = e.target[2].value;
        const file = e.target[3].files[0];

        const phoneRegex = /^1[3-9]\d{9}$/;

        if (!phoneRegex.test(phone)) {
            setErr(true);
            return; // 如果手机号格式不正确，终止函数执行
        }
        if (password !== confirmPassword) {
            setErr(true);
            return; // 阻止表单提交
        }
        setErr(false);

        // const formData = new FormData();
        // // formData.append("displayName", displayName);
        // formData.append("phone ", phone);
        // formData.append("password", password);
        // // formData.append("file", file);

        // createUserWithEmailAndPassword(auth, email, password)
        //     // .then((userCredential) => {
        //     //     // Signed in
        //     //     const user = userCredential.user;
        //     //     console.log(user);
        //     //     // ...
        //     // })
        //     // .catch((error) => {
        //     //     const errorCode = error.code;
        //     //     const errorMessage = error.message;
        //     //     // ..
        //     // });

        // try {
                  //Create user
        const response = await axios.post('http://47.119.191.51:9990/api/register', {
            phone,
            password
        }, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
            const { message, user, downloadURL } = response.data;
            if (message !== "success") {
                setResMessage(message);
                setRegisterErr(true);
                return;
            }
            else{
                if (user && downloadURL) {
                    setResMessage("注册成功，即将跳转到登录页面");
                    setTimeout(() => {
                        navigate("/login");
                    }, 2000);
                }

                //   await uploadBytesResumable(storageRef, file).then(() => {
                //     getDownloadURL(storageRef).then(async (downloadURL) => {
                //       try {
                //         //Update profile
                //         await updateProfile(res.user, {
                //           displayName,
                //           photoURL: downloadURL,
                //         });
                //         //create user on firestore
                //         await setDoc(doc(db, "users", res.user.uid), {
                //           uid: res.user.uid,
                //           displayName,
                //           email,
                //           photoURL: downloadURL,
                //         });

                //         //create empty user chats on firestore
                //         await setDoc(doc(db, "userChats", res.user.uid), {});
                //         navigate("/");
                //       } catch (err) {
                //         console.log(err);
                //         seterr(true);
                //         setLoading(false);
                //       }
                //     });
                //   });
                // } catch (err) {
                //     // setErr(true);
                //     // console.log(err);
                // //         //   setLoading(false);
                // }
            }
    };

    return (
        <div className="formContainer">
            <div className="formWrapper">
                <span className="logo"><img className="log" src={logo} alt=""/></span>
                <span className="title"></span>
                <form onSubmit={handleSubmit}>
                    {/*<input type="text" placeholder="Enter Your First Name"/>*/}
                    <input type="tel" placeholder="输入手机号"/>
                    <input type="password" placeholder="输入密码"/>
                    <input type="password" placeholder="确认密码"/>
                    <input style={{display: "none"}} type="file" id="file"/>
                    <label htmlFor="file">
                        <img src={Add} alt=""/>
                        <span>Add an avatar</span>
                        <span className="size">(File Size should be less than 300 KB)</span>
                    </label>

                    <button>注册</button>
                    {/* disable loading */}
                    {/* {loading && "Uploading and compressing the image please wait..."} */}
                    {err && <span className="er">* 手机号格式有误或密码不一致</span>}
                    {registerErr && <span className="er">{resMessage}</span>}
                    {registerErr || <span className="info">{resMessage}</span>}
                </form>
                <p>
                    已经有账号？ <Link to="/login" className="link">请登录</Link>
                </p>
            </div>
        </div>
    );
};

export default Register;