import React, { useState, useEffect, useCallback } from 'react';

const ChatRoom = ({ onNewMessage }) => {
    const [message, setMessage] = useState('');

    // 使用 useCallback 钩子来定义 sendMessage 函数
    const sendMessage = useCallback(() => {
        if (message.trim()) {
            // 注意：在这里我们不能直接使用 chatWs，因为它是在 useEffect 内部定义的
            // 我们需要将 chatWs 定义在 useRef 中，以便在 sendMessage 中使用
        }
    }, [message]);

    useEffect(() => {
        const chatWs = new WebSocket('ws://47.119.191.51:9990/ws_chat');
        chatWs.onopen = () => console.log('Chat WebSocket connected');
        chatWs.onmessage = (event) => {
            onNewMessage(event.data);
        };
        chatWs.onclose = () => console.log('Chat WebSocket closed');
        chatWs.onerror = (error) => console.error('Chat WebSocket error:', error);

        // 在 useEffect 内部使用 sendMessage
        const sendMessageEffect = () => {
            if (message.trim()) {
                chatWs.send(message);
                setMessage('');
            }
        };

        // 将 sendMessageEffect 绑定到事件处理中
        document.addEventListener('keypress', sendMessageEffect);
        const button = document.createElement('button');
        button.addEventListener('click', sendMessageEffect);

        return () => {
            chatWs.close();
            document.removeEventListener('keypress', sendMessageEffect);
            button.removeEventListener('click', sendMessageEffect);
        };
    }, [message]); // 依赖项列表中包含 message，以确保 message 变化时重新运行 useEffect

    return (
        <div>
            <input
                type="text"
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                onKeyPress={(e) => e.key === 'Enter' && sendMessage()}
            />
            <button onClick={sendMessage}>Send</button>
        </div>
    );
};

export default ChatRoom;